import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { getItemList } from '../../actions'
import Paginate from '../Reusable/Paginate'
import MainNavigation from './MainNavigation'
import SearchBar from '../SearchBar/SearchBar'
import SupportPopover from './SupportPopover'
import SolutionPopover from './SolutionPopover'
import WhoWeServePopover from './WhoWeServePopover'
import MyAccountPopover from './MyAccountPopover'
import ShoppingCartPopover from './ShoppingCartPopover'
import { TabletAndSmaller, Desktop } from '../../shared/responsive-breakpoints'
import DealmedSVG from '../../shared/icons/DealmedSVG'
import PhoneSVG from '../../shared/icons/PhoneSVG'
import ReorderSVG from '../../shared/icons/ReorderSVG'
import '../SearchBar/SearchBar.scss'
import { getPageName, getPageDescription } from '../../shared/switchPageURLSelector'
import { Statsig, useGate } from 'statsig-react'
import { SearchbarNew } from '../SearchBar/SearchbarNew'
import mixpanel from 'mixpanel-browser'
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent'
import NotificationPopover from './NotificationPopover'
import { sanitizeInput } from '../../utils/sanitizeInput'
import HomeAlertBanner from '../Home/HomeAlertBanner';
import { ContentfulContext } from '../../shared/ContentfulContext'
import MainNavigationMenu from './MainNavigationMenu'
import MainNavigationLink from './MainNavigationLink'
import ContentPopover from './ContentPopover'
import MainNavigationItems from './MainNavigationItems'
import AlertBanner from './AlertBanner'
import MainNavgationOLD from './MainNavgationOLD'

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)


const Header = props => {
  const {
    searchTerm,
    itemListLoading,
    itemList,
    isAuthenticated,
    accountLoading,
    email,
    firstName,
    solutionsPagesData,
    accountNumber
  } = props

  const [isSupportPopoverOpen, setIsSupportPopoverOpen] = useState(false)
  const [isMyAcccountPopoverOpen, setIsMyAcccountPopoverOpen] = useState(false)
  const [isShoppingCartPopoverOpen, setIsShoppingCartPopoverOpen] = useState(false)
  const [isNotificationPopoverOpen, setIsNotificationPopoverOpen] = useState(false)
  const [activePopoverId, setActivePopoverId] = useState(null)

  const alertBannerData = useContext(ContentfulContext)
  .find((x) => x.sys.contentType.sys.id == 'homePage').fields?.homepageSections?.[0]?.fields //this will need to change if/when the homepage is generated on Contentful again
  

  const mainNavData = useContext(ContentfulContext)
  .filter((x) => x.sys.contentType.sys.id == 'navBar')[0].fields.navBarSections
  .filter((x) => x.sys.contentType.sys.id == 'mainNavigation')[0].fields.navigationSections

  const MainNavDropdownsAndLinks = mainNavData.map((item, index) => (
    <MainNavigationItems
    key={index}
    data={item}
    index={index + 1}
    activePopoverId={activePopoverId}
    setActivePopoverId={setActivePopoverId}
    className="dealmed-header__info-list"
  />
        ))

  const locationPathName = props.location.pathname

  const pageName = useMemo(() =>
    getPageName(locationPathName, props.accountName, [locationPathName]),
  )
  const pageDescription = useMemo(() => getPageDescription(locationPathName), [locationPathName])

  const newAccountPage = useGate('htd_my_account_sales_orders_menu_desktop').value
  const displayAlgoliaSearchbar = useGate('algolia_autocomplete_searchbar').value

  const activeMenu = useSelector(state => state.myAccount.activeMenu)
  const isSidebarCollapsed = useSelector(state => state.myAccount.isSidebarCollapsed)
  
  const logClickEvent = ({ eventType, metaData }) => {
		mixpanelLogEvent({ 
		  eventType, 
		  userDetails:props?.auth?.user, 
      metaData
		})
	}


  const handleAlgoliaSearchBarClick = async searchTerm => {
    const { PRODUCT_OBJ, setPaginationFields, executeSearchAndRedirect } = props
    const trimmedSearchTerm = sanitizeInput(searchTerm.trim())

    if (!trimmedSearchTerm) return

    await props.resetSelectedCategory()

    setPaginationFields({
      reducerProp: PRODUCT_OBJ.reducerProp,
      data: { numPaginateSection: 1, page: 1 },
    })

    props.setSearch(trimmedSearchTerm)
    
    logClickEvent({
      eventType:'search',
      metaData:{searchTerm:trimmedSearchTerm}
    })
 
    executeSearchAndRedirect()
    window.scrollTo(0, 0)
  }

  const handleSearchBarClick = async () => {
    const { searchTerm, PRODUCT_OBJ, setPaginationFields, executeSearchAndRedirect } = props
    const trimmedSearchTerm = searchTerm.trim()

    if (!trimmedSearchTerm) return

    await props.resetSelectedCategory()

    setPaginationFields({
      reducerProp: PRODUCT_OBJ.reducerProp,
      data: { numPaginateSection: 1, page: 1 },
    })

    props.setSearch(trimmedSearchTerm)
    executeSearchAndRedirect()
  }

  const handleTypeAheadSearch = value => {
    const trimmedValue = value.trim()

    if (trimmedValue.length < 3 || !trimmedValue) return
    props.getItemList({ search: trimmedValue })
  }

  const handleSearchBarChange = value => {
    props.setSearchTerm(value)
    handleTypeAheadSearch(value)
  }

  

  const mobileDealmedHeader = (
    <>
      <div className="dealmed-header__top">
        <div className="dealmed-container">
          <div className="dealmed-header__row-group">
            <div className="dealmed-header__navigation">
              {/* <MainNavigation solutionsPagesData={solutionsPagesData} /> */}
              <MainNavgationOLD solutionsPagesData={solutionsPagesData} />
            </div>
            <div className="dealmed-header__logo" 
              onClick={() => {
                logClickEvent({eventType:'Header_Dealmed_Logo_Click'})}}
              >
              <Link to="/" className="dealmed-header__logo-link">
                <DealmedSVG className="dealmed-header__logo-icon" />
              </Link>
            </div>
          </div>
          <div className="dealmed-header__row-group">
          <div className="dealmed-header__reorder">
              <Link to="/myaccount/menu/reorder">
                <span className="visually-hidden">Reorder</span>
                <ReorderSVG className="dealmed-header__reorder-icon" />
              </Link>
            </div>
            <div className="dealmed-header__cart">
              <ShoppingCartPopover
                isOpen={isShoppingCartPopoverOpen}
                openPopover={() => setIsShoppingCartPopoverOpen(true)}
                closePopover={() => setIsShoppingCartPopoverOpen(false)}
                className="dealmed-header__cart-popover"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="dealmed-header__bottom">
        <div className="dealmed-container">
          <div className="dealmed-header__search">
            {displayAlgoliaSearchbar ? (
              <SearchbarNew
                default={searchTerm}
                value={searchTerm}
                isLoading={itemListLoading}
                placeholder="Search for products"
                onClick={handleAlgoliaSearchBarClick}
                onChange={handleSearchBarChange}
                options={itemList}
              />
            ) : (
              <SearchBar
                default={searchTerm}
                value={searchTerm}
                isLoading={itemListLoading}
                placeholder="Search for products"
                onClick={handleSearchBarClick}
                onChange={handleSearchBarChange}
                options={itemList}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )

  const desktopDealmedHeader = (
    <>
      <div className="dealmed-header__top">
        <div className="dealmed-header__info">
          <ul className="dealmed-header__info-list">
            {MainNavDropdownsAndLinks}
          </ul>
          {alertBannerData && <AlertBanner alertBannerData={alertBannerData} />}

          <ul className="dealmed-header__info-list">
            <li className="dealmed-header__info-item">
                <SupportPopover
                  isOpen={isSupportPopoverOpen}
                  openPopover={() => setIsSupportPopoverOpen(true)}
                  closePopover={() => setIsSupportPopoverOpen(false)}
                  className="dealmed-header__support-popover"
                />
            </li>
          </ul>
          
        </div>
          <div className="dealmed-header__main">
            <div className="dealmed-header__logo"
            onClick={() => {
                  logClickEvent({eventType:'Header_Dealmed_Logo_Click'})}}
            >
              <Link to="/" className="dealmed-header__logo-link">
                <DealmedSVG className="dealmed-header__logo-icon" />
              </Link>
            </div>
            <div className="dealmed-header__search">
              {displayAlgoliaSearchbar ? (
                <SearchbarNew
                  default={searchTerm}
                  value={searchTerm}
                  isLoading={itemListLoading}
                  placeholder="Search for products"
                  onClick={handleAlgoliaSearchBarClick}
                  onChange={handleSearchBarChange}
                  options={itemList}
                />
              ) : (
                <SearchBar
                  default={searchTerm}
                  value={searchTerm}
                  isLoading={itemListLoading}
                  placeholder="Search for products"
                  onClick={handleSearchBarClick}
                  onChange={handleSearchBarChange}
                  options={itemList}
                />
              )}
            </div>
            <div className="dealmed-header__right">
              <div className='dealmed-header__right-menus'>
              {firstName ? (
                  <div className="dealmed-header__account">
                    <MyAccountPopover
                      isOpen={isMyAcccountPopoverOpen}
                      openPopover={() => setIsMyAcccountPopoverOpen(true)}
                      closePopover={() => setIsMyAcccountPopoverOpen(false)}
                      className="dealmed-header__account-popover"
                    />
                  </div>
                ) : (
                  <Link to="/myaccount" className="dealmed-header__account-login">
                    Login
                  </Link>
                )}
                {!accountLoading && !isAuthenticated ? (
                      <Link to="/register" className="dealmed-header__register-link">
                        Create Account
                      </Link>
                ) : null}
                <div className="dealmed-header__right-icons">
                  <NotificationPopover
                    isOpen={isNotificationPopoverOpen}
                    openPopover={() => setIsNotificationPopoverOpen(true)}
                    closePopover={() => setIsNotificationPopoverOpen(false)}
                    className="dealmed-header__cart-popover"
                  />
                  <ShoppingCartPopover
                    isOpen={isShoppingCartPopoverOpen}
                    openPopover={() => setIsShoppingCartPopoverOpen(true)}
                    closePopover={() => setIsShoppingCartPopoverOpen(false)}
                    className="dealmed-header__cart-popover"
                  />
                </div>
              </div>
            </div>
            
          </div>
        <MainNavigation solutionsPagesData={solutionsPagesData}/>

      </div>
    </>
  )

  return (
    <>
      <header className="site-header">
        <div className="dealmed-header__body">
          <a href="#main-content" className="dealmed-header__skip-link">
            Skip to Main Content
          </a>
          <TabletAndSmaller>{mobileDealmedHeader}</TabletAndSmaller>
          <Desktop>{desktopDealmedHeader}</Desktop>
        </div>
      </header>
      {locationPathName.includes('/myaccount') && newAccountPage && (
        <div className="subheader-container">
          <div
            className={`myaccount-breadcrumb-row ${
              isSidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-not-collapsed'
            }`}
          >
            <div className="my-account-leftbox">
              <div className="my-account-text">My Account </div>
            </div>
            <div className="myaccount-page-name-description-col col">
              <div className="breadcrumbs-text">
                <span className="home-breadcrumb">
                  <Link to="/">{`Home / `} </Link>{' '}
                </span>{' '}
                {(activeMenu === 'Company' || activeMenu === 'Billing') && (
                  <span>{activeMenu} / </span>
                )}
                {pageName}
              </div>
              {pageName !== 'My Account' && (
                <div className="page-title-description-container">
                  <span className="page-name-text">{pageName}</span>
                  <span className="description-name-text">{pageDescription}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
	return {
		itemListLoading: state.loader.itemListLoading,
		itemList: state.shopping.itemList,
		isAuthenticated: pathOr(false, ['user', 'id'], state.auth),
		accountLoading: state.loader.accountLoading,
		firstName: pathOr('', ['user', 'firstName'], state.auth),
		email: pathOr('', ['user', 'email'], state.auth),
		accountName: pathOr('', ['user', 'account', 'accountName'], state.auth),
    accountNumber: pathOr('', ['user', 'account', 'id'], state.auth),
    auth: state.auth
	}
}

export default withRouter(connect(mapStateToProps, { getItemList })(Paginate(Header)))