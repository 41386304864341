import React, { useEffect } from 'react';
import classnames from 'classnames';
import Spinner from './Spinner';

const Popover = React.lazy(() => import('@reach/popover'));

const DealmedPopover = (props) => {
	const { innerRef, targetRef, position, closePopover, className, children, isContentPopover = false } = props;

	const handleOutisdeClick = (e) => {
		if (innerRef.current === null || targetRef.current === null) {
			return;
		}

		if (
			targetRef.current === e.target ||
			targetRef.current.contains(e.target) ||
			innerRef.current === e.target ||
			innerRef.current.contains(e.target)
		) {
			return;
		}

		closePopover();
	};

	const handleBlur = (e) => {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			closePopover();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleOutisdeClick, false);

		return () => {
			document.removeEventListener('mousedown', handleOutisdeClick, false);
		};
	});

	return (
		<React.Suspense fallback={<Spinner />}>
			<Popover ref={innerRef} targetRef={targetRef} position={position} className={classnames('dealmed-popover', className)} as={'div'}>
				<div className={classnames('dealmed-popover__body', {
						'dealmed-popover__body--override-position': isContentPopover,
					})} 
					onBlur={handleBlur}
				>
					<div className="dealmed-popover__content">{children}</div>
				</div>
			</Popover>
		</React.Suspense>
	);
};

export default DealmedPopover;
