import React, { useContext, useReducer, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { useMediaQuery } from "react-responsive";
import { selectCategory } from "../../actions";
import classnames from "classnames";
import MainNavigationHeader from "./MainNavigationHeader";
// import MainNavigationMenu from "./MainNavigationMenu";
import MainNavigationMenu from "./MainNavigationMenuOLD";
import MainNavigationLink from "./MainNavigationLinkOLD";
import Paginate from "../Reusable/Paginate";
import HamburgerMenuSVG from "../../shared/icons/HamburgerMenuSVG";
import { ContentfulContext } from '../../shared/ContentfulContext';
import { Statsig } from 'statsig-react';
import { ShoppingContext } from '../../shared/ShoppingContext'
import { mixpanelLogEvent } from "../../utils/mixpanelLogEvent";


const initialState = {
  mainNavigationIsOpen: false,
  activeMenus: [],
};

const mainNavigationReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_MAIN_NAVIGATION":
      return {
        ...state,
        mainNavigationIsOpen: true,
      };

    case "CLOSE_MAIN_NAVIGATION":
      return {
        ...state,
        mainNavigationIsOpen: false,
        activeMenus: [],
      };

    case "UPDATE_ACTIVE_MENUS":
      // If the menuId of the menu we just clicked on is already in the state,
      // we want to remove it and any menus at a higher depth than it
      if (
        state.activeMenus.some((menu) => menu.menuId === action.payload.menuId)
      ) {
        return {
          ...state,
          activeMenus: state.activeMenus.filter((menu) => {
            if (
              menu.menuId !== action.payload.menuId &&
              menu.depth < action.payload.depth
            ) {
              return true;
            }
          }),
        };
      }

      // If there exists a menu with the same depth as an active menu, we want
      // to remove the menu with the same depth and add the new one in
      if (
        state.activeMenus.some((menu) => menu.depth === action.payload.depth)
      ) {
        const filteredActiveMenus = state.activeMenus.filter((menu) => {
          if (menu.depth < action.payload.depth) {
            return true;
          }
        });

        return {
          ...state,
          activeMenus: [
            ...filteredActiveMenus,
            {
              menuId: action.payload.menuId,
              depth: action.payload.depth,
              menuElement: action.payload.menuElement,
            },
          ],
        };
      }

      // Otherwise, just simply add the menu to the state array
      return {
        ...state,
        activeMenus: [
          ...state.activeMenus,
          {
            menuId: action.payload.menuId,
            depth: action.payload.depth,
            menuElement: action.payload.menuElement,
          },
        ],
      };

    default:
      return state;
  }
};

const MainNavigation = (props) => {
  const {
    categories = [],
    selectCategory,
    onSubCategoryClick,
    executeSearchAndRedirect,
    history
  } = props

  const { setFacetsForFiltering } = useContext(ShoppingContext)

  const mainNavData = useContext(ContentfulContext)
  .filter((x) => x.sys.contentType.sys.id == 'navBar')[0].fields.navBarSections
  .filter((x) => x.sys.contentType.sys.id == 'mainNavigation')[0].fields.navigationSections

  const [{ mainNavigationIsOpen, activeMenus }, dispatch] = useReducer(
    mainNavigationReducer,
    initialState
  );
  const mainNavigationRef = useRef(null);
  const mainNavigationToggleBtn = useRef(null);

  const currentlyActiveMenu =
    activeMenus.length > 0
      ? activeMenus[activeMenus.length - 1].menuId
      : undefined;

    const logClickEvent = ( {eventType, metaData} ) => {
      mixpanelLogEvent({ 
        eventType, 
        userDetails:props.auth.user,
        metaData
        })
      }

  const MainNavigationItems =  ({ data, index }) => {
    return (
      <>
        {data.sys.contentType.sys.id === 'mainNavigationDropdown' ? (
          <li className="old-dealmed-main-navigation__item" key={data.sys.id} >
            <MainNavigationMenu
              menuId={data.sys.id}
              isExpanded={activeMenus.some((menu) => menu.menuId == data.sys.id)}
              isCurrentlyActive={
                currentlyActiveMenu !== undefined
                  ? currentlyActiveMenu === data.sys.id
                  : false
              }
              text={data.fields.title}
              depth={0}
              parentMenu="Main Menu"
              activeMenus={activeMenus}
              dispatch={dispatch}
            >
              {data.fields.pages.map((page, index) => (
                <li className="old-dealmed-main-navigation__item" key={index} >
                  <MainNavigationLink
                    path={page.fields.path}
                    text={page.fields.title}
                    onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}
                  />
                </li>
              ))}
            </MainNavigationMenu>
          </li>
        ) : (
          <li className="old-dealmed-main-navigation__item" key={index} >
            <MainNavigationLink
              path={data.fields.path}
              text={data.fields.title}
              onClick={() => dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}
            />
          </li>
        )}
      </>
    )
  }

  const MainNavLinks = mainNavData.map((item, index) => (
          <MainNavigationItems key={index} data={item} index={index+1} />
        ))
  
  const handleOutsideClick = (e) => {
    if (!mainNavigationRef?.current?.contains(e.target)) {
      dispatch({ type: "CLOSE_MAIN_NAVIGATION" });
    }
  };

  const handleBlur = (e) => {
    if (e.relatedTarget === null) {
      return;
    }

    if (!e.currentTarget.contains(e.relatedTarget)) {
      dispatch({ type: "CLOSE_MAIN_NAVIGATION" });

      if (!resize.matches) {
        mainNavigationToggleBtn.current?.focus();
      }
    }
  };

  const handleChange = () => {
    dispatch({ type: "CLOSE_MAIN_NAVIGATION" });
  };

  const resize = useMediaQuery({ minWidth: 767 }, undefined, handleChange);

  const mainNavigationWrapperClasses = classnames(
    "old-dealmed-main-navigation__wrapper",
    {
      "old-dealmed-main-navigation__wrapper--expanded": mainNavigationIsOpen,
    }
  );

  // useEffect(() => {
  //   document.addEventListener("click", handleOutsideClick, {capture:true});
  //   return () => document.removeEventListener("click", handleOutsideClick);
  // }, []);

  useEffect(() => { //this way prevents memory leaks than ^^
    let isMounted = true;
  
    document.addEventListener("click", handleOutsideClick, {capture:true});
  
    return () => {
      isMounted = false;
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  
  return (
    <>
      <nav
        ref={mainNavigationRef}
        className="old-dealmed-main-navigation"
        id="main-navigation"
      >
        <button
          ref={mainNavigationToggleBtn}
          type="button"
          className="old-dealmed-main-navigation__toggle-btn"
          aria-expanded={mainNavigationIsOpen}
          onClick={() => dispatch({ type: "OPEN_MAIN_NAVIGATION" })}
        >
          <span className="visually-hidden">Open Main Navigation</span>
          <HamburgerMenuSVG className="old-dealmed-main-navigation__toggle-btn-icon" />
        </button>
        <div className={mainNavigationWrapperClasses} onBlur={handleBlur}>
          <MainNavigationHeader dispatch={dispatch} />
          <div className="old-dealmed-main-navigation__body">
            <ul className="old-dealmed-main-navigation__list">
              <li className="old-dealmed-main-navigation__item">
                <MainNavigationMenu
                  menuId={0}
                  isExpanded={activeMenus?.some((menu) => menu.menuId === 0)}
                  isCurrentlyActive={
                    currentlyActiveMenu !== undefined
                      ? currentlyActiveMenu === 0
                      : false
                  }
                  text="Products"
                  depth={0}
                  parentMenu="Main Menu"
                  activeMenus={activeMenus}
                  dispatch={dispatch}
                >
                  {categories.flatMap(cat => cat.children).map((category, index) => {

                    
                    const isCurrentlyActive =
                      currentlyActiveMenu !== undefined
                        ? currentlyActiveMenu === category.id
                        : false
                    
                    const onCategoryClick = (categoryPath) => {
                      dispatch({ type: "CLOSE_MAIN_NAVIGATION" })
                      setFacetsForFiltering({})
                      logClickEvent({eventType:`NavBar_Category_Click`, metaData:{category:category.name}})
                      history.push(`/category/${categoryPath}`)
                    }

                    return (
                      <li
                        key={category.id}
                        className={`old-dealmed-main-navigation__item${isCurrentlyActive ? ' isSelected' : ''}`}                        
                      >
                   
                        <MainNavigationMenu
                          menuId={category.id}
                          categoryPath={category.urlComponent}
                          isExpanded={activeMenus.some(
                            (menu) => menu.menuId === category.id
                          )}
                          isCurrentlyActive={isCurrentlyActive}
                          text={category.name}
                          depth={1}
                          parentMenu={
                            category.isParent ? "Products" : category.name
                          }
                          activeMenus={activeMenus}
                          dispatch={dispatch}
                          currentlyActiveMenu={currentlyActiveMenu}

                          // opensOnHover={true}
                          // onCategoryClick={onCategoryClick}
                        >
                          {category.children.map((subCategory) => {
                            return (
                              <li
                                key={subCategory.id}
                                className="old-dealmed-main-navigation__item"
                              >
                                <MainNavigationLink
                                  text={subCategory.name}
                                  path={() => {
                                    const queryParam = subCategory.urlComponent;
                                    return (
                                      "/products/" +
                                      (queryParam && queryParam !== ""
                                        ? queryParam
                                        : "")
                                    );
                                  }}
                                  onClick={async () => {
                                    dispatch({ type: "CLOSE_MAIN_NAVIGATION" })
                                    logClickEvent({eventType:`NavBar_Subcategory_Click`, metaData:{subcategory:subCategory.name}})
                                    selectCategory(category)
                                    setFacetsForFiltering({})
                                    await onSubCategoryClick({ subCategory })
                                    executeSearchAndRedirect()
                                  }}
                                />
                              </li>
                            );
                          })}
                        </MainNavigationMenu>
                      </li>
                      
                    );
                  })}
                </MainNavigationMenu>

              </li>
              {MainNavLinks}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth:state.auth,
    categories: state.shopping.categories,
  };
};

export default connect(mapStateToProps, { selectCategory })(
  Paginate(MainNavigation)
);
