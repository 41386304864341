import { Link } from 'react-router-dom'

function formatDateString(dateString) {     //for datestrings like 20240525
    return `${dateString.slice(4, 6)}/${dateString.slice(6)}/${dateString.slice(0, 4)}`;
}

function convertToAmPm(timeString) { // give AM/PM time
    let [hours, minutes, seconds] = timeString.split(':');
    hours = parseInt(hours, 10);
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    hours = hours < 10 ? '0' + hours : hours;
    return `${hours}:${minutes}:${seconds} ${amPm}`;
}

export const createNotificationMessage = (notification) => {
    switch (notification?.notificationType) {
        case "Fulfillment Delivered":{
            const { fulfillmentId, deliveryDate, timeOfDelivery, workwaveId} = notification?.metaData
            return (
                <div className="dealmed-header__notification-card__message-div">
                    <p className="dealmed-header__notification-card__message-div__title">Delivery</p>
                    <p>{`Fulfillment: ${workwaveId || fulfillmentId} was delivered on ${formatDateString(deliveryDate)} at ${convertToAmPm(timeOfDelivery)}`}</p>
                </div>
                )};
        case "Backorder Ready":{
            const { item, quantityPreviouslyBackordered} = notification?.metaData
            return (
                <div className="dealmed-header__notification-card__message-div">
                    <p className="dealmed-header__notification-card__message-div__title">Backorder Ready</p>
                    <p>{`${item.displayName}, Qty:${quantityPreviouslyBackordered}: Preparing For Shipment`}</p>
                </div>
                )};
        case "Item Back in Stock":{
            const { item } = notification?.metaData
            return (
                <div className="dealmed-header__notification-card__message-div">
                    <p className="dealmed-header__notification-card__message-div__title">Item Back in Stock</p>
                    <p>{`${item.displayName} Is Back In Stock!`}</p>
                </div>
                )};
        default:
            return 'You have a new notification.';
    }
}